html, body, #root, .containerRoot{
 height:100%;
 background-color:#ffffff !important;

}

.containerRoot{
  display:flex !important;
  flex-direction:column;
  
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
#trustedsite-tm-image{
  display:none;
}
.mfes-trustmark{
  margin:auto; 
  margin-right:0;
}

/*App Spiner Component*/

#nbspinneroverlay{
  position:absolute;
  left:0;
  background: rgba(255,255,255,0.3);
  width:100%; 
  height:100vh; 
  z-index:999; 
  display:block; 


}
#nbspinneroverlaymobile{
  position:absolute;
  left:0;
  background: rgba(255,255,255,0.3);
  width:100%; 
  height:100vh; 
  z-index:999; 
  display:none; 
}

.nbspinner {
  width: 75px;
  height: 75px;
  margin: auto;
  background: transparent;
  border-top: 4px solid #20037d;
  border-right: 4px solid transparent;
  border-radius: 50%;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
  z-index:9999; 
  margin-top:40vh; 
}


@-webkit-keyframes spin {
  from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}
        
@keyframes spin {
  from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}
  /*********************************/
@media screen and (max-width: 640px) {
  .trustedsite-trustmark{
    width:115px !important;
    height:65px   !important;
    }
    .mfes-trustmark{
    width:115px  !important;
    height:65px  !important;
    }
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* hide bar behind mui slider */
.WithStyles\(ForwardRef\(Slider\)\)-markActive-116 {
  opacity: 0 !important;
}
